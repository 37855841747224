<template>
    <div ref="offerView">
        <img class="offer-hero-image" :src="heroImageUrl" />
        <h1 v-if="isClosed && !isFullyBooked" class="text-danger mt-4 text-center">
            Bokningen är inte längre aktiv
        </h1>
        <h1 v-if="isFullyBooked && !isClosed" class="text-danger mt-4 text-center">
            Bokaklippet är fullbokat
        </h1>
        <div class="offer-wrapper">
            <h1 class="offer-header">
                {{ title }}
            </h1>
            <p class="offer-description">
                {{ description }}
            </p>
            <p class="offer-collectionDates">
                Hämtas mellan: {{this.formattedDate(this.collectionStartDate)}} och {{this.formattedDate(this.collectionEndDate)}}
            </p>
            <p class="offer-collectionDates">
                Bokning aktiv till: {{this.formattedDate(this.endDate)}}
            </p>
            <!-- <p class="offer-your-discount">
                Din rabatt: {{ this.formattedPercentage(discount) }}
            </p> -->
            <div v-if="!isClosed">
                <div class="mt-4">
                    <div v-for="(variant, index) in variants" :key="index" >
                        <div v-if="variant.maxOrders !== 0" class="row mb-4">
                            <div class="col-5 mb-3 align-self-center">
                                <div class="d-flex">
                                    <input
                                    v-if="!showCode"
                                    type="number"
                                    :id="'quantity' + index"
                                    v-model="variant.quantity"
                                    class="form-control form-control-sm mr-2"
                                    :class="{ 'is-invalid': variant.errors.quantity && !this.hasOrderedAtLeastOne}"
                                    :max="variant.maxOrders"
                                    @focus="$event.target.select()"
                                    />
                                    <span v-if="showCode" class="qtylabel form-label align-self-center">{{ variant.quantity }}</span>
                                    <label :for="'quantity' + index" class="qtylabel form-label align-self-center">{{ variant.unit }}</label>
                                </div>
                                <label style="font-size: 0.8rem" class="text-danger" v-if="variant.errors.quantity && !this.hasOrderedAtLeastOne"> {{ variant.errors.quantity }} </label>
                            </div>
                            <div class="col-7">
                                <h4>{{ variant.variant }}</h4>
                                <p>Bokaklipp pris {{ variant.discountedPrice }}kr</p>
                                <p>Ord.pris {{ variant.price }}kr</p>
                                <p>{{variant.percentage}}% Rabatt</p>
                                <p>Minst {{ variant.minOrders }} per kund</p>
                                <p>Max {{ variant.maxOrders }} per kund</p>
                                <p>{{variant.note}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showCode && barcodePayload && barcodePayload.length > 0"  class="d-flex flex-column align-items-center offer-qr-code">
                    <h3>Tack för din bokning!</h3>
                </div>
                <div v-if="showCode && barcodePayload && barcodePayload.length > 0" class="d-flex flex-column align-items-center offer-qr-code">
                    <QrcodeVue :value="barcodePayload ?? barcode" :size="qrCodeSize" level="H" />
                </div>
                <div v-if="isGeneralLink && !isFullyBooked" class="container">
                    <div class="mb-3">
                        <label for="phone" class="form-label">Mobilnummer</label>
                        <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': errors.phoneNumber }" v-model="phoneNumber">
                        <label style="font-size: 0.8rem" class="text-danger" v-if="errors.phoneNumber"> {{ errors.phoneNumber }} </label>
                    </div>
                    <div class="offer-activate-button">
                        <Spinner v-if="isLoading && !showCode"/>
                        <button v-else class="btn primary text-right" @click="activate">
                            <span >Aktivera erbjudande</span>
                        </button>
                    </div>
                </div>
                <div v-if="barcode && barcode.length > 0" class="d-flex flex-column align-items-end offer-activate-button">
                    <div v-if="isLoading && !showCode">
                        <Spinner/>
                    </div>
                    <div v-else class="btn primary text-right" @click="activate">
                        <span v-if="showCode == false">Aktivera erbjudande</span>
                        <span v-if="showCode">Din rabattkod: {{ barcode }}</span>
                    </div>
                </div>
                <div v-if="!isGeneralLink" class="d-flex flex-column align-items-center">
                    <div v-if="hasOptedOut == null || hasOptedOut == false" class="btn offer-opt-out-button text-center" @click="optOut">
                        Jag vill inte ta del av fler erbjudanden från {{ merchantName }}
                    </div>
                    <div v-else class="offer-opt-out-button text-center" @click="optOut">
                        Du kommer inte få fler erbjudanden från {{ merchantName }}
                    </div>
                </div>
                <div v-else class="d-flex flex-column align-items-center">
                    <div class="btn offer-opt-out-button text-center" @click="redirectToSubscription">
                        Jag vill bara prenumerera på bokaklipp från {{ merchantName }}
                    </div>
                </div>
            </div>
            <div  class="footer-image container d-flex justify-content-center align-items-center mb-2">
                <img src="../assets/img/brand/logo.png" alt="Bokaklipp logo" />
            </div>
        </div>
    </div>
</template>

<script>
import { getOffer, activateOffer, createOffer, optOutFromOffer, getCampaignById, getOrdersByCampaignId } from '@/firebase'
import { useRoute, useRouter } from 'vue-router'
import QrcodeVue from 'qrcode.vue'
import { toast } from 'vue3-toastify'
import Spinner from "../components/misc/Spinner.vue";

export default {
    name: "OfferView",
    data() {
        return {
            isGeneralLink: false,
            active: null,
            barcode: null,
            barcodePayload: null,
            barcodeType: null,
            merchantId: null,
            merchantName: null,
            description: null,
            discount: null,
            endDate: null,
            heroImageUrl: null,
            startDate: null,
            title: null,
            hasOptedOut: null,

            showCode: false,

            campaignId: null,

            heroImageWidth: 600,
            heroImageHeight: 300,
            circleRadius: 16,
            qrCodeSize: 200,

            phoneNumber: '',
            offerId: '',
            errors: {
                phoneNumber: null
            },

            collectionStartDate: null,
            collectionEndDate: null,
            variants: [],
            hasOrderedAtLeastOne: false,

            isClosed: false,
            isFullyBooked: false,
            isLoading: false
        }
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    async mounted() {
        try {
            const route = useRoute()
            
            const { 
                phoneNumber,
                offerId,
                campaignId
            } = route.params
            if(offerId && phoneNumber){
                this.isGeneralLink = false
                const offer = await getOffer(phoneNumber, offerId)
                const orders = await getOrdersByCampaignId(offer.merchantId, offerId)
                this.active = offer.active
                this.barcode = offer.barcode
                this.barcodePayload = offer.barcodePayload
                this.barcodeType = offer.barcodeType
                this.merchantId = offer.merchantId
                this.merchantName = !offer.merchantName || offer.merchantName.length === 0? 'Din Butik': offer.merchantName
                this.description = offer.description
                this.endDate = offer.endDate
                this.heroImageUrl = offer.photoData
                this.startDate = offer.startDate
                this.title = offer.title
                this.hasOptedOut = offer.hasOptedOut
                this.collectionStartDate = offer.collectionStartDate
                this.collectionEndDate = offer.collectionEndDate

                this.variants = offer.variants.map((v) => {
                    const totalOrders = orders[v.sku]?.total || 0;
                    const remainingStock = Math.max(0, v.maxDiscountQuantity - totalOrders);

                    return {
                        ...v,
                        quantity: v?.quantity ?? 0,
                        errors: { quantity: null },
                        maxOrders: Math.min(v.maxOrders, remainingStock),
                        minOrders: v.minOrders > remainingStock ? 0 : v.minOrders
                    };
                });

                this.campaignId = this.offerId

                this.showCode = offer.activated
                this.phoneNumber = this.sanitizePhoneNumber(phoneNumber)
                this.offerId = offerId
            } else {
                this.isGeneralLink = true;
                const campaign = await getCampaignById(campaignId)
                const orders = await getOrdersByCampaignId(campaign.creator, campaignId)
                this.campaignId = campaignId
                this.active = campaign.active
                this.merchantName = !campaign.merchantName || campaign.merchantName.length === 0? 'Din Butik': campaign.merchantName
                this.merchantId = campaign.creator
                this.description = campaign.description
                this.endDate = campaign.endDate
                this.heroImageUrl = campaign.photoData
                this.startDate = campaign.startDate
                this.title = campaign.title
                this.collectionStartDate = campaign.collectionStartDate
                this.collectionEndDate = campaign.collectionEndDate
                this.variants = campaign.variants.map((v) => {
                    const totalOrders = orders[v.sku]?.total || 0;
                    const remainingStock = Math.max(0, v.maxDiscountQuantity - totalOrders);

                    return {
                        ...v,
                        quantity: 0,
                        errors: { quantity: null },
                        maxOrders: Math.min(v.maxOrders, remainingStock),
                        minOrders: v.minOrders > remainingStock ? 0 : v.minOrders
                    };
                });
                this.showCode = campaign.activated
            }
            let endDate = new Date(this.endDate.seconds * 1000)
            this.isClosed = endDate < Date.now() || !this.active && !this.showCode

            this.isFullyBooked = !this.isClosed && this.variants.filter(v => v.maxOrders !== 0).length === 0;
            
            
        } catch (error) {
            console.error(error)
        }
    },
    methods: {
        async activate() {
            try {
                this.isLoading = true;
                // validate values
                let formInvalid = false;
                this.hasOrderedAtLeastOne = false;

                this.variants = this.variants.map(v => {
                    const { errors } = v;
                    if(!this.hasOrderedAtLeastOne && v.quantity !== 0 && v.quantity >= v.minOrders && v.quantity <= v.maxOrders) {
                        this.hasOrderedAtLeastOne = true;
                    }
                    if(!this.hasOrderedAtLeastOne && (v.quantity === 0 || v.quantity < v.minOrders || v.quantity > v.maxOrders)) {
                        formInvalid = true;
                        errors.quantity = `Vänligen välj ett nummer ${v.minOrders} - ${v.maxOrders}`
                    } else {
                        errors.quantity = null
                    }
                    return {...v, errors}
                })

                if(this.isGeneralLink && this.phoneNumber.length === 0) {
                    formInvalid = true;
                    this.errors.phoneNumber = 'Du måste ange ett giltigt telefonnummer'
                } else {
                    this.errors.phoneNumber = null;
                }
                if(!formInvalid) {
                    const orders = this.variants.filter(v => v.quantity > 0).map(v => ({sku: v.sku, quantity: v.quantity}))
                    if(this.isGeneralLink){
                        await createOffer({
                            phoneNumber: this.sanitizePhoneNumber(this.phoneNumber),
                            campaignId: this.campaignId,
                            userID: this.merchantId
                        })
                        await activateOffer({
                            phoneNumber: this.sanitizePhoneNumber(this.phoneNumber),
                            offerId: this.campaignId,
                            orders: { [this.sanitizePhoneNumber(this.phoneNumber)]: orders}
                        })
                        this.showCode = true
                        this.loading = false
                        toast.success('Successfully placed order')
                        this.router.push(`/offer/${this.sanitizePhoneNumber(this.phoneNumber)}-${this.campaignId}`).then(() => {
                            // Reload the current page after navigating to the new route
                            window.location.reload(true);
                        });
                    } else {
                        await activateOffer({
                            phoneNumber: this.sanitizePhoneNumber(this.phoneNumber),
                            offerId: this.offerId,
                            orders: { [this.sanitizePhoneNumber(this.phoneNumber)]: orders}
                        })
                        this.showCode = true
                        this.loading = false
                    }
                    this.$refs.offerView.scrollIntoView({ behavior: 'smooth' });
                } else {
                    this.isLoading = false;
                }
            } catch (error) {
                console.error(error)
                this.showCode = false
                this.isLoading = false;
            }
        },
        async optOut() {
            try {
                this.hasOptedOut = true
                await optOutFromOffer(this.merchantId, this.sanitizePhoneNumber(this.phoneNumber), this.offerId)
            } catch(error) {
                console.error(error)
            }
        },
        redirectToSubscription() {
            this.router.push(`/subscribe/${this.campaignId}`)
        },
        formattedAmount(amount) {
            return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amount)
        },
        formattedPercentage(percent) {
            return new Intl.NumberFormat('sv-SE', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(percent)
        },
        formattedDate(date) {
            if(date)
                return new Intl.DateTimeFormat('sv-SE', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }).format(new Date(date.seconds * 1000));
        },
        sanitizePhoneNumber(phoneNumber) {
            const cleanedNumber = decodeURI(phoneNumber).replace(/\D+/g, '');
            if (cleanedNumber.startsWith('0')) {
                return `46${cleanedNumber.slice(1)}`;
            } else if (cleanedNumber.startsWith('46')) {
                return cleanedNumber;
            }
            if (cleanedNumber.startsWith('46')) {
                return cleanedNumber;
            }
            return `46${cleanedNumber}`;
        },
    },
    computed: {
    },
    components: {
        QrcodeVue,
        Spinner
    }
}
</script>

<style>
p {
    margin: 0;
}
.offer-hero-image {
    width: 100%;
    object-fit: contain;
}
.offer-wrapper {
    margin: 0 auto;
    width: 80vw;
    font-family: monospace;
    padding-top: 2em;
    padding-bottom: 20px;
}
.offer-header {
    font-size: 35px;
    padding-bottom: 10px;
}
.offer-description {
    padding-bottom: 10px;
}
.offer-collectionDates {
    padding-bottom: 10px;
}
.offer-your-discount {
    padding-bottom: 2em;
}
.offer-qr-code {
    padding-bottom: 2em;
}
.offer-activate-button {
    padding-bottom: 3em;
}
.qtylabel {
    margin-left: 10px;
}
.offer-opt-out-button {
    background: transparent !important;
    /* color: black; */
    margin-bottom: 2em;
    color: red;
    text-decoration: underline;
}

.payl-color {
    color: rgb(32, 183, 230);
}
.btn {
  border-color: rgb(32, 183,248);
  background:rgb(32, 183,248);
}
.btn:hover {
  border-color: rgb(28, 161, 218);
  background:rgb(28, 161, 218);
}
.btn-outline-primary {
  background: white;
  color: rgb(32, 183,248);
}
.progress-bar {
  background:rgb(32, 183,248);
}
.btn-outline-primary {
  color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:focus-visible {
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:active {
  color: #fff;
  background-color: rgb(32, 183, 248);
  border-color: rgb(32, 183, 248);
}
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(32 183 248 / 50%);
}
.footer-image img { 
    width: 154px;
}
.is-invalid{
    outline: 1px solid #dc3545;
}
</style>